import React from 'react'
import styled from 'styled-components'
import CodeImage from './code-image'

/* Component text */

const text = {
  headline: 'Built for Developers.',
  detail:
    "The true benefits of open banking will only be realized when interactive, intuitive, and developer friendly API's are created. We utilize our custom machine learning algorithm to fetch & provide organized data.",
}

/* Component */

const Developer = () => {
  return (
    <DeveloperContainer>
      <CopyContainer>
        <Headline>{text.headline}</Headline>
        <Detail>{text.detail}</Detail>
      </CopyContainer>
      <ImageContainer>
        <CodeImage />
      </ImageContainer>
    </DeveloperContainer>
  )
}

/* Component export */

export default Developer

/* Component styling */

const DeveloperContainer = styled.div`
  padding: 15px;

  @media screen and (min-width: 568px) {
    margin: 50px auto 0px;
    max-width: 1200px;
  }
`

const CopyContainer = styled.div`
  @media screen and (min-width: 568px) {
    max-width: 95%;
    padding: 0px 30px;
  }
`

const ImageContainer = styled.div`
  @media screen and (min-width: 568px) {
    width: 85%;
    margin: 0px auto;
  }
`

const Headline = styled.h3`
  margin: 15px 0px;

  @media screen and (min-width: 568px) {
    font-size: calc(16px + (55 - 16) * ((100vw - 568px) / (1200 - 568)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 55px;
  }
`

const Detail = styled.h6`
  color: #cbcbcb;
  font-weight: 100;
  margin: 20px 0px;

  @media screen and (min-width: 568px) {
    font-size: calc(12px + (30 - 12) * ((100vw - 568px) / (1200 - 568)));
    line-height: 130%;
  }

  @media screen and (min-width: 1200px) {
    font-size: 30px;
  }
`
