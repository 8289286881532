import { useStaticQuery, graphql } from 'gatsby'

/* data query multiple images */

const imagesData = () => {
  const data = useStaticQuery(graphql`
    query {
      lendingImage: file(relativePath: { eq: "lending.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      paymentsImage: file(relativePath: { eq: "payments.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      personalImage: file(relativePath: { eq: "personal.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return data
}

/* Data export */

export default imagesData
