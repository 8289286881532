import React, { Fragment } from 'react'
import Layout from '../components/layout'
import Intro from '../components/intro'
import Summary from '../components/summary'
import Banner from '../components/banner'
import Developer from '../components/developer'
import Products from '../components/products'
import Uses from '../components/uses'
import SEO from '../components/reference/seo'

const IndexPage = () => (
  <Fragment>
    <Layout>
      <SEO title="Home" />
      <Intro />
      <Summary />
      <Banner />
      <Developer />
      <Products />
      <Uses />
    </Layout>
  </Fragment>
)

export default IndexPage
