import React from 'react'
import styled from 'styled-components'
import DeskImage from './desk-image'

/* Component text */

const text = {
  headline: 'Products for growth',
  detail: "Developer focused API's & documentation to access our product suite",
}

const boxText = {
  auth: {
    headline: '/Auth',
    detail: 'Instantly authenticate account information',
  },
  score: {
    headline: '/Score',
    detail: 'Utilize our custom credit score for customers',
  },
  identity: {
    headline: '/Identity',
    detail: 'Connect to our digital onboarding module',
  },
  balance: {
    headline: '/Balance',
    detail: 'Verify real time account balances',
  },
  transactions: {
    headline: '/Transactions',
    detail: 'Access detailed transaction history',
  },
}

/* Component */

const Products = () => {
  return (
    <ProductsContainer>
      <CopyContainer>
        <Headline>{text.headline}</Headline>
        <Detail>{text.detail}</Detail>
      </CopyContainer>
      <ImageContainer>
        <DeskImage />
        <AuthBox>
          <BoxHeadline>{boxText.auth.headline}</BoxHeadline>
          <BoxDetail>{boxText.auth.detail}</BoxDetail>
        </AuthBox>
        <ScoreBox>
          <BoxHeadline>{boxText.score.headline}</BoxHeadline>
          <BoxDetail>{boxText.score.detail}</BoxDetail>
        </ScoreBox>
        <IdentityBox>
          <BoxHeadline>{boxText.identity.headline}</BoxHeadline>
          <BoxDetail>{boxText.identity.detail}</BoxDetail>
        </IdentityBox>
        <BalanceBox>
          <BoxHeadline>{boxText.balance.headline}</BoxHeadline>
          <BoxDetail>{boxText.balance.detail}</BoxDetail>
        </BalanceBox>
        <TransactionsBox>
          <BoxHeadline>{boxText.transactions.headline}</BoxHeadline>
          <BoxDetail>{boxText.transactions.detail}</BoxDetail>
        </TransactionsBox>
      </ImageContainer>
    </ProductsContainer>
  )
}

/* Component export */

export default Products

/* Component styling */

const ProductsContainer = styled.div`
  @media screen and (min-width: 568px) {
    max-width: 1200px;
    margin: 50px auto;
  }
`

const CopyContainer = styled.div`
  margin: 30px 15px 10px 20px;

  @media screen and (min-width: 568px) {
    max-width: 95%;
    padding: 0px 30px;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  position: relative;
`

const Headline = styled.h3`
  margin: 15px 0px;

  @media screen and (min-width: 568px) {
    font-size: calc(16px + (55 - 16) * ((100vw - 568px) / (1200 - 568)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 55px;
  }
`

const Detail = styled.h6`
  color: #cbcbcb;
  font-weight: 100;
  margin: 10px 0px;

  @media screen and (min-width: 568px) {
    font-size: calc(12px + (30 - 12) * ((100vw - 568px) / (1200 - 568)));
    line-height: 130%;
  }

  @media screen and (min-width: 1200px) {
    font-size: 30px;
  }
`

const BoxHeadline = styled.h5`
  font-size: 12px;
  margin: 5px 10px 0px;

  @media screen and (min-width: 568px) {
    font-size: calc(14px + (30 - 14) * ((100vw - 568px) / (1200 - 568)));
    font-weight: 400;
  }

  @media screen and (min-width: 1200px) {
    font-size: 30px;
  }
`

const BoxDetail = styled.h6`
  font-size: 8px;
  font-weight: 200;
  margin: 0px 10px 5px;
  white-space: nowrap;

  @media screen and (min-width: 568px) {
    font-size: calc(12px + (18 - 12) * ((100vw - 568px) / (1200 - 568)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 18px;
    font-weight: 100;
  }
`

const Box = styled.div`
  color: white;
  display: inline-block;
  position: absolute;
  transition: border-radius 2s;

  @media screen and (min-width: 568px) {
    padding: 5px 20px;
  }

  @media screen and (min-width: 1200px) {
    border-radius: 100px;
  }
`

const AuthBox = styled(Box)`
  background-color: #5879ed;
  top: 20%;
  border-radius: 0px 100px 100px 0px;
`

const ScoreBox = styled(Box)`
  background-color: #001768;
  top: 37.5%;
  border-radius: 0px 100px 100px 0px;
`

const IdentityBox = styled(Box)`
  background-color: #d94aa8;
  top: 55%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 100px;
`

const BalanceBox = styled(Box)`
  background-color: #5879ed;
  top: 20%;
  right: 0px;
  border-radius: 100px 0px 0px 100px;
`

const TransactionsBox = styled(Box)`
  background-color: #d94aa8;
  top: 37.5%;
  right: 0px;
  border-radius: 100px 0px 0px 100px;
`
