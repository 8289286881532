import React from 'react'
import styled from 'styled-components'

/* Component text */

const text = {
  headline: 'Give users access to their own financial data in your app.',
  detail:
    'Spade provides a seamless experience for your users. Let us provide you with the data you need including bank account verification, transaction history, KYC onboarding, & risk scores.',
}

/* Component */

const Banner = () => {
  return (
    <BannerContainer>
      <CopyContainer>
        <Headline>{text.headline}</Headline>
        <Detail>{text.detail}</Detail>
      </CopyContainer>
    </BannerContainer>
  )
}

/* Component export */

export default Banner

/* Component styling */

const BannerContainer = styled.div`
  background-color: #5879ed;
  margin: 10px 0px;
  color: white;
  width: 100vw;
  padding: 15px;

  @media screen and (min-width: 568px) {
    margin-top: 20px;
  }
`

const CopyContainer = styled.div`
  margin: 0px auto;
  max-width: 1200px;

  @media screen and (min-width: 568px) {
    padding: 20px 30px;
  }
`

const Headline = styled.h6`
  font-weight: 550;
  font-size: 12.8px;
  margin-bottom: 8px;

  @media screen and (min-width: 568px) {
    font-size: calc(16px + (42 - 16) * ((100vw - 568px) / (1200 - 568)));
    white-space: nowrap;
  }

  @media screen and (min-width: 1200px) {
    font-size: 42px;
  }
`

const Detail = styled.p`
  line-height: 130%;
  font-size: 10px;
  font-weight: 100;
  white-space: pre-wrap;

  @media screen and (min-width: 568px) {
    font-size: calc(12px + (30 - 12) * ((100vw - 568px) / (1200 - 568)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 30px;
  }
`
