import React, { useState } from 'react'
import styled from 'styled-components'
import IntroImage from './intro-image'
import FormSignUp from '../sign-up'
import Button from '../sign-up/button'

/* Component text */

const text = {
  headline: 'Build Amazing Fintech Apps.',
  detail: 'Financial tools to accelerate growth.',
  detailTablet:
    'Financial tools to accelerate Fintech \n& Microfinance growth.',
  button: 'Get Early Access!',
}

/* Component */

const Intro = () => {
  const [open, setOpen] = useState(false)

  return (
    <IntroContainer>
      <CopyContainer>
        <Headline>{text.headline}</Headline>
        <Detail>{text.detail}</Detail>
        <DetailTablet>{text.detailTablet}</DetailTablet>
        <ButtonContainer>
          <Button type="button" onClick={() => setOpen(true)}>
            {text.button}
          </Button>
        </ButtonContainer>
      </CopyContainer>
      {open && <FormSignUp onClose={() => setOpen(false)} />}
      <ImageContainer>
        <IntroImage />
      </ImageContainer>
    </IntroContainer>
  )
}

/* Component export */

export default Intro

/* Component styling */

const IntroContainer = styled.div`
  margin: 20px;

  @media screen and (min-width: 568px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
  }
`

const CopyContainer = styled.div`
  @media screen and (min-width: 568px) {
    margin: 15% 0px 15px 40px;
    grid-column: 1/3;
    grid-row: 1/1;
  }
`

const ButtonContainer = styled.div`
  display: none;

  @media screen and (min-width: 568px) {
    margin-top: 15px;
    display: block;
  }
`

const ImageContainer = styled.div`
  width: 85%;
  margin: 30px auto auto;

  @media screen and (min-width: 568px) {
    justify-self: end;
    grid-column: 2/5;
    grid-row: 1/1;
    margin-right: -10px;
    margin-top: 0px;
  }
`

const Headline = styled.h3`
  display: inline;

  @media screen and (min-width: 568px) {
    display: block;
    white-space: nowrap;
    font-size: calc(19px + (42 - 19) * ((100vw - 568px) / (1200 - 568)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 42px;
  }
`

const Detail = styled.h4`
  @media screen and (min-width: 568px) {
    display: none;
  }
`

const DetailTablet = styled.h4`
  display: none;

  @media screen and (min-width: 568px) {
    display: block;
    white-space: pre-wrap;
    padding-top: 10px;
    line-height: 1.2;
    font-weight: 400;
    font-size: calc(14px + (30 - 14) * ((100vw - 568px) / (1200 - 568)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 30px;
  }
`
