import React from 'react'
import styled from 'styled-components'
import imagesData from './images'
import Box from './box'
import Background from './graph-background'

/* Component text */

const onboarding = {
  headline: 'Digital onboarding',
  detail:
    'Create a great customer \nonboarding experience & \nreach a wider audience.',
}

const connection = {
  headline: 'Connection to \nbank & wallet data',
  detail:
    'Utilize customer financial \ndata to build better \nproducts for users.',
}

const risk = {
  headline: 'Advanced risk analysis',
  detail: 'Greatly improve your \ncustomer risk analysis \nwith Spade score.',
}

/* Component */

const Summary = () => {
  const images = imagesData()
  return (
    <BoxesContainer>
      <Background>
        <Box
          headline={onboarding.headline}
          detail={onboarding.detail}
          imageData={images.onboardingImage.childImageSharp.fluid}
          borderColor="#001768"
        />
        <Box
          headline={connection.headline}
          detail={connection.detail}
          imageData={images.connectionImage.childImageSharp.fluid}
          borderColor="#fecfef"
        />
        <Box
          headline={risk.headline}
          detail={risk.detail}
          imageData={images.riskImage.childImageSharp.fluid}
          borderColor="#7e97f2"
        />
      </Background>
    </BoxesContainer>
  )
}

/* Component export */

export default Summary

/* Component styling */

const BoxesContainer = styled.div`
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
`
