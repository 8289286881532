import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

/* Component */

const Box = ({ headline, detail, imageData, borderColor }) => {
  return (
    <BoxContainer borderColor={borderColor}>
      <ImageContainer>
        <Img fluid={imageData} />
      </ImageContainer>
      <CopyContainer>
        <Headline>{headline}</Headline>
        <Detail>{detail}</Detail>
      </CopyContainer>
    </BoxContainer>
  )
}

/* Component export */

export default Box

/* Component styling */

const BoxContainer = styled.div`
  margin-bottom: 25px;

  @media screen and (min-width: 568px) {
    margin: 50px 15px;
  }
`

const CopyContainer = styled.div``

const ImageContainer = styled.div`
  max-width: 30%;
  margin: auto;

  @media screen and (min-width: 568px) {
    max-width: 70%;
    min-height: 115px;
    margin-bottom: 10px;
  }
`

const Headline = styled.h4`
  text-align: center;
  font-weight: bold;
  padding: 10px;

  @media screen and (min-width: 568px) {
    font-size: calc(22px + (42 - 22) * ((100vw - 568px) / (1200 - 568)));
    font-weight: 900;
  }

  @media screen and (min-width: 1200px) {
    font-size: 42px;
  }
`

const Detail = styled.h5`
  text-align: center;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 400;
  white-space: pre-wrap;

  @media screen and (min-width: 568px) {
    font-size: calc(14px + (22 - 14) * ((100vw - 568px) / (1200 - 568)));
    font-weight: 500;
    line-height: 1.5;
  }

  @media screen and (min-width: 1200px) {
    font-size: 22px;
  }
`
