import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

/* Component */

const Box = ({ headline, detail, imageData, borderColor }) => {
  return (
    <BoxContainer borderColor={borderColor}>
      <CopyContainer>
        <Headline>{headline}</Headline>
        <Detail>{detail}</Detail>
      </CopyContainer>
      <ImageContainer>
        <Img fluid={imageData} />
      </ImageContainer>
    </BoxContainer>
  )
}

/* Component export */

export default Box

/* Component styling */

const BoxContainer = styled.div`
  margin: 70px auto;
  min-height: 100px;
  max-width: 300px;
  border: 2px solid;
  border-color: ${props => (props.borderColor ? props.borderColor : 'black')};
  border-radius: 25px 5px 5px 5px;

  @media screen and (min-width: 568px) {
    margin: 70px 2%;
    width: 33%;
    padding-top: 15%;
    position: relative;
    max-width: 2250px;
  }
`

const CopyContainer = styled.div`
  padding: 25px;
  padding-bottom: 0px;
  white-space: pre-wrap;

  @media screen and (min-width: 568px) {
    padding: 6%;
    position: absolute;
    top: 0px;
    left: 0px;
  }
`

const ImageContainer = styled.div`
  width: 80px;
  position: relative;
  margin: auto;
  top: 35px;

  @media screen and (min-width: 568px) {
    width: 40%;
    top: 53%;
    overflow: visible;
  }
`

const Headline = styled.h2`
  display: inline;

  @media screen and (min-width: 568px) {
    display: inline-block;
    font-size: calc(14px + (32 - 14) * ((100vw - 568px) / (1200 - 568)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 32px;
  }
`

const Detail = styled.h4`
  font-size: 18px;
  line-height: normal;
  font-weight: 200;

  @media screen and (min-width: 568px) {
    font-size: calc(10px + (22 - 10) * ((100vw - 568px) / (1200 - 568)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 22px;
    font-weight: 100;
  }
`
