import React from 'react'
import styled from 'styled-components'
import imagesData from './images'
import Box from './box'
import Background from './nodes-image'

/* Component text */

const onboarding = {
  headline: 'Lending',
  detail: 'Balance, transaction, information, & score for better underwriting',
}

const connection = {
  headline: 'Payments',
  detail: 'Authenticate accounts to send & receive payments',
}

const risk = {
  headline: 'Personal',
  detail: 'Analyze & categorize your transactions across multiple accounts',
}

const text = {
  headline: 'Use Cases',
  detail: 'Spade leads to new & improved products across the Fintech landscape',
}

/* Component */

const Uses = () => {
  const images = imagesData()
  return (
    <UsesContainer>
      <TextWrapper>
        <CopyContainer>
          <Headline>{text.headline}</Headline>
          <Detail>{text.detail}</Detail>
        </CopyContainer>
      </TextWrapper>
      <ImageContainer>
        <BackgroundImage />
      </ImageContainer>
      <BoxesContainer>
        <Box
          headline={onboarding.headline}
          detail={onboarding.detail}
          imageData={images.lendingImage.childImageSharp.fluid}
          borderColor="DarkBlue"
        />
        <Box
          headline={connection.headline}
          detail={connection.detail}
          imageData={images.paymentsImage.childImageSharp.fluid}
          borderColor="pink"
        />
        <Box
          headline={risk.headline}
          detail={risk.detail}
          imageData={images.personalImage.childImageSharp.fluid}
          borderColor="blue"
        />
      </BoxesContainer>
    </UsesContainer>
  )
}

/* Component export */

export default Uses

/* Component styling */

const UsesContainer = styled.div`
  position: relative;
`

const BackgroundImage = styled(Background)`
  width: 150%;
  left: -100px;

  @media screen and (min-width: 568px) {
    width: 100%;
    left: 0px;
  }
`

const ImageContainer = styled.div`
  position: absolute;
  max-height: 750px;
  width: 100%;
  overflow: hidden;

  @media screen and (min-width: 568px) {
    top: -10px;
  }
`

const CopyContainer = styled.div`
  margin: 30px 15px 10px 20px;

  @media screen and (min-width: 568px) {
    max-width: 95%;
    padding: 0px 30px;
  }
`

const TextWrapper = styled.div`
  margin: 0px auto;
  max-width: 1200px;
`

const Headline = styled.h3`
  margin: 15px 0px;

  @media screen and (min-width: 568px) {
    font-size: calc(16px + (55 - 16) * ((100vw - 568px) / (1200 - 568)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 55px;
  }
`

const Detail = styled.h6`
  color: #cbcbcb;
  font-weight: 100;
  margin: 10px 0px;

  @media screen and (min-width: 568px) {
    font-size: calc(12px + (30 - 12) * ((100vw - 568px) / (1200 - 568)));
    line-height: 130%;
  }

  @media screen and (min-width: 1200px) {
    font-size: 30px;
  }
`

const BoxesContainer = styled.div`
  @media screen and (min-width: 568px) {
    max-width: 1200px;
    margin: 0px auto;
    display: flex;
    justify-content: space-evenly;
  }
`
